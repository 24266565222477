import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {APIService} from '../../../@core/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfigService} from '../../../@core/config.service';
import {ToastService} from '../../../@core/toast.service';
import {RequestHandler} from '../../../@core/utils/request-handler';
import {UnsubscribeComponent} from '../../../@core/fc-component';

@Component({
    selector: 'app-set-password',
    templateUrl: './set-password.component.html',
    styleUrls: [
        './set-password.component.scss',
    ],
})
export class SetPasswordComponent extends UnsubscribeComponent implements OnInit {
    @ViewChild('termsTmp', {static: true}) termsTemplate: TemplateRef<any>;
    private _queryParams: any;
    isValid: boolean;
    email: string;
    password: string;
    showPassword = false;
    acceptTerms = false;
    errors: any;
    siteName = this.config.siteName;
    domainText = this.config.domainText;
    getCodeVerificationRequest = new RequestHandler(
        () => this.api.CheckVerificationCode.get(this._queryParams),
        {
            destroy$: this.destroy$,
        }
    );
    postCodeVerificationRequest = new RequestHandler(
        () => this.api.CheckVerificationCode.post({password: this.password, password_confirm: this.password}, this._queryParams),
        {
            destroy$: this.destroy$,
        }
    );

    constructor(private config: ConfigService,
                private api: APIService,
                private route: ActivatedRoute,
                private router: Router,
                private toastService: ToastService,
                private modalService: NgbModal) {
        super();
    }

    ngOnInit() {
        this.verify();
    }

    verify() {
        this._queryParams = {...this.route.snapshot.queryParams};

        if (this._queryParams?.email) {
            this._queryParams.email = this._queryParams.email.replace('+', '%2B');

            this.getCodeVerificationRequest.call().subscribe({
                next: res => {
                    this.email = res.email;
                    this.isValid = true;
                },
                error: () => {
                    this.email = null;
                    this.isValid = false;
                },
            });
        } else {
            this.email = null;
            this.isValid = false;
        }
    }

    onSubmit() {
        if (this.isValid && this.acceptTerms) {
            this.postCodeVerificationRequest.call().subscribe({
                next: () => {
                    this.toastService.success('The password was saved successfully.');
                    this.router.navigate([this._queryParams.redirect_to || '/login'], {replaceUrl: true});
                },
                error: err => {
                    const error = err.error || err;
                    this.errors = error.detail || error.password ? error : {detail: 'An error occurred while trying to save the password.'};
                },
            });
        } else {
            this.errors = {
                terms: 'Please read & accept our terms to be able to access the system',
            };
        }
    }

    openTerms(event?: MouseEvent) {
        if (event) event.stopPropagation();
        this.modalService.open(this.termsTemplate, {size: 'lg'});
    }

    onAcceptTermsChange(accepted: boolean) {
        this.acceptTerms = accepted;
        if (this.acceptTerms) {
            this.openTerms();
            setTimeout(() => this.acceptTerms = false);
        }
    }

    requestNewPassword() {
        this.router.navigate(['request-password'], {queryParams: {email: this._queryParams.email}});
    }
}
