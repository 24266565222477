<ng-container [ngSwitch]="status">
    <app-illustration-message *ngSwitchCase="'SUCCESS'"
                              [title]="'Authorization successful'"
                              [circleColor]="['success'|color, .33]"
                              iconName="check">
    </app-illustration-message>
    <app-illustration-message *ngSwitchCase="'ERROR'"
                              [title]="'Error'"
                              [circleColor]="['danger'|color, .33]"
                              iconName="times"
                              caption="An error occurred during authorization. Please try again.">
    </app-illustration-message>
    <app-illustration-message *ngSwitchCase="'NO_USER'"
                              [title]="'Session Expired'"
                              [circleColor]="['danger'|color, .33]"
                              iconName="times"
                              caption="Please start the authorization process over">
    </app-illustration-message>
    <app-spinner *ngSwitchCase="'PENDING'"></app-spinner>
</ng-container>
<button class="btn btn-lg btn-outline-dark mt-4" (click)="setStatus('CANCELED')" *ngIf="status === 'PENDING'; else defButton">Cancel</button>
<ng-template #defButton>
    <button class="btn btn-lg btn-primary mt-3" (click)="emitClose()">Ok</button>
</ng-template>
