<app-card *appRequestHandler="authorizationRequest">
    <div class="d-flex justify-content-center">
        <app-user-icon [user]="application" size="lg" hasTooltip="false"></app-user-icon>
    </div>
    <div class="d-flex align-items-center justify-content-center mb-2 mt-4 section-head">
        <div class="strong">{{application?.first_name}} </div><div class="ml-1">is requesting access to:</div>
    </div>
    <div class="form-group scopes">
        <div *ngFor="let scope of scopes" class="d-flex mb-2">
            <app-toggle [(ngModel)]="scope.value" [disabled]="scope.key === 'launch/patient' || scope.key === 'launch/encounter'" [id]="scope.key"></app-toggle>
            <label style="max-width: 400px" class="text-dark mb-0" [for]="scope.key">{{scope.text}}</label>
        </div>
    </div>
    <div class="form-group" *ngIf="isPractitioner">
        <app-patient-select [formControl]="patientControl"
                            [bindValue]="null"
                            [searchTermMinLength]="3"
                            class="flex-grow-0 flex-shrink-1"
                            style="width: 320px; min-width: 200px;">
        </app-patient-select>
    </div>
    <div class="form-group" *ngIf="application?.needsEncounter && selectedPatient">
        <div class="mt-2">
            <div class="section-head">Please select an Encounter.</div>
            <app-advanced-input type="select"
                                [selectItems]="encounters"
                                bindValue="fhir_id"
                                bindLabel="fhir_id"
                                class="body-select w-100"
                                [(ngModel)]="selectedEncounter">
            </app-advanced-input>
        </div>
    </div>
    <div class="d-flex justify-content-end">
        <button class="btn" (click)="navigateBack()">Cancel</button>
        <button class="btn btn-primary"
               [disabled]="isPractitioner && (!selectedPatient && application?.needsPatient) || (!selectedEncounter && application?.needsEncounter)"
               (click)="onSubmit()"
               [appUnderProcessing]="submitRequest.isPending">
            Continue
        </button>
    </div>
</app-card>

