<ng-container>
    <h3 class="heading">Sign in</h3>
    <ng-container *ngTemplateOutlet="errorMsg"></ng-container>
    <form class="form" data-test-id="login-form">
        <div class="form-group">
            <div class="error-list" *ngIf="messages && messages.email">{{messages.email}}</div>
            <label for="email">{{configService.loginUserLabel}}</label>
            <input autofocus type="text" [(ngModel)]="email" #tempRefU id="email" name="email">
        </div>
        <div class="form-group">
            <div class="error-list" *ngIf="messages && messages.password">{{messages.password}}</div>
            <label for="password">Password</label>
            <input type="password" [(ngModel)]="password" #tempRefPw id="password" name="password">
        </div>
        <div class="submit-cnt">
            <button class="secondary-btn" type="button" (click)="requestNewPassword()">Can't login?</button>
            <button (click)="onSubmit()" type="submit" [appUnderProcessing]="loginRequest.isPending">Sign in</button>
        </div>
    </form>
</ng-container>
<ng-template #errorMsg>
    <div class="error-list" *ngIf="messages && messages.non_field_errors && messages.non_field_errors.length > 0">
        <div *ngFor="let error of messages.non_field_errors">{{error}}</div>
    </div>
</ng-template>
