import {Injectable} from '@angular/core';
import {Route, Router, UrlSegment, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {UserAuthService} from '../@core/user-auth.service';
import {catchError, map, take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PortalGuard {
    constructor(private router: Router,
                private userAuth: UserAuthService) {
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> {
        return this.userAuth.user.pipe(take(1), map(u => {
            if (u) return true;

            return this._getLoginUrl(segments);
        }), catchError(() => of(this._getLoginUrl(segments))));
    }

    private _getLoginUrl(segments: UrlSegment[]) {
        const targetQueryParams = this.router.getCurrentNavigation().extractedUrl.queryParams;
        const target = segments.reduce((path, x) => `${path}/${x}`, '');
        return this.router.createUrlTree(['/login'], target ? {queryParams: {target, ...targetQueryParams}} : undefined);
    }
}
