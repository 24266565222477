import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {TwoFactorAuthService} from './two-factor-auth.service';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {CONSTANTS, getConstSet} from '../../../../@core/constants';
import {ToastService} from '../../../../@core/toast.service';

@Injectable()
export class TwoFactorAuthInterceptor implements HttpInterceptor {
    get passcode() {
        return this.twoFactorAuthService.passcode;
    }

    set passcode(x) {
        this.twoFactorAuthService.passcode = x;
    }

    constructor(private twoFactorAuthService: TwoFactorAuthService,
                private toastService: ToastService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler, displayError = false): Observable<HttpEvent<any>> {
        return next.handle(
            this.passcode ?
                req.clone({setHeaders: {passcode: this.passcode}}) :
                req
        ).pipe(catchError(err => {
            if (err.status === 403 && Object.values(getConstSet('TWO_FACTOR_AUTH_ERROR')).includes(err.error.detail)) {
                if (displayError) this.toastService.error(this.getErrorMessage(err.error.detail));

                return this.twoFactorAuthService.openTwoFactorAuthModal().pipe(
                    tap(passcode => this.passcode = passcode),
                    switchMap(() => this.intercept(req, next, true)),
                );
            }

            return throwError(err);
        }));
    }

    getErrorMessage(error: string) {
        switch (error) {
            case CONSTANTS.TWO_FACTOR_AUTH_ERROR_WRONG_PASSCODE:
                return 'Wrong or expired passcode';
            case CONSTANTS.TWO_FACTOR_AUTH_ERROR_NO_PASSCODE:
                return 'No passcode received';
            default:
                return error;
        }
    }
}
