import {Component, OnInit} from '@angular/core';
import {UnsubscribeComponent} from '../../@core/fc-component';
import {ActivatedRoute} from '@angular/router';
import {UserAuthService} from '../../@core/user-auth.service';
import {PharmacyService, AyvaAuthorizationStatus} from '../../@core/pharmacy.service';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-ayva-authorization-success',
    templateUrl: './ayva-authorization-success.component.html',
    styleUrls: ['./ayva-authorization-success.component.scss'],
})
export class AyvaAuthorizationSuccessComponent extends UnsubscribeComponent implements OnInit {
    status: AyvaAuthorizationStatus = 'PENDING';

    constructor(private route: ActivatedRoute,
                private userAuth: UserAuthService,
                private pharmacyService: PharmacyService) {
        super();
    }

    ngOnInit() {
        this.userAuth.user.pipe(takeUntil(this.destroy$)).subscribe(u => {
            if (!u) return this.setStatus('NO_USER');

            const {code} = this.route.snapshot.queryParams;
            if (!code) return this.setStatus('ERROR');

            this.pharmacyService.Authorize$({authCode: code}).subscribe(() => this.setStatus('SUCCESS'), () => this.setStatus('ERROR'));
        });
    }

    setStatus(status: AyvaAuthorizationStatus) {
        this.status = status;
        this.pharmacyService.emitAuthorizationResponse(status, window.top);

        if (status === 'SUCCESS') setTimeout(() => this.emitClose(), 3000);
        if (status === 'CANCELED') this.emitClose();
    }

    emitClose() {
        this.pharmacyService.closeAuthorization(window.top);
    }
}
