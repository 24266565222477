import {ChangeDetectorRef, Component} from '@angular/core';
import {extractErrorMessage} from '../../../utils/error.utils';
import {RequestHandler} from '../../../@core/utils/request-handler';
import {UserAuthService} from '../../../@core/user-auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UnsubscribeComponent} from '../../../@core/fc-component';
import {ConfigService} from '../../../@core/config.service';

type CANT_LOGIN_STATUS = 'ERROR' | 'SENT';
@Component({
    selector: 'app-cant-login',
    templateUrl: './cant-login.component.html',
    styleUrls: ['../login/login.component.scss'],
})
export class CantLoginComponent extends UnsubscribeComponent {
    messages: any;
    email: string;
    forgotPwRequest = new RequestHandler(
        email => this.userAuthService.forgotPassword(email),
        {
            showErrorToast: false,
            destroy$: this.destroy$,
        }
    );
    errorMsgTitle: string = 'Contact support to reset your password';
    cantLoginStatus: CANT_LOGIN_STATUS = 'ERROR';
    private _queryParams = this.activatedRoute.snapshot.queryParams;

    constructor(public configService: ConfigService,
                private userAuthService: UserAuthService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private ref: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        if (this._queryParams?.email) {
            this.email = this._queryParams.email;
        }
    }

    onSendPwEmail() {
        this.forgotPwRequest.call(this.email).subscribe(() => {
            this.cantLoginStatus = 'SENT';
        }, error => {
            this.cantLoginStatus = 'ERROR';
            this.errorMsgTitle = extractErrorMessage(error, 'request password reset link');
            this.messages = error.non_field_errors || error.email || error.password ? error : {non_field_errors: [error.message]};
        }, () => {
            this.ref.markForCheck();
        });
    }

    navigateBack() {
        this.router.navigate(['login'], {queryParams: {email: this._queryParams.email}});
    }
}
