import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class ParamConverterInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let needsUpdate = false;
        let params: HttpParams = req.params;
        params.keys().forEach(key => {
            params.getAll(key).forEach(value => {
                if (value as any instanceof Date) {
                    needsUpdate = true;
                    const ISODate = new Date(value).toISOString();
                    params = params.delete(key, value).append(key, ISODate);
                } else if (typeof value === 'boolean') {
                    needsUpdate = true;
                    params = params.delete(key, value).append(key, value ? '1' : '0');
                }
            });
        });
        if (needsUpdate) req = req.clone({params});
        return next.handle(req);
    }
}
