import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {VersionService} from '../version.service';
import {tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable()
export class VersionCheckInterceptor implements HttpInterceptor {
    constructor(private versionService: VersionService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // If we are in development mode we don't want to update the hash in redis after every change.
        // If tests are running we need to skip the early return part in the line of 17.
        if (!environment.production && !environment.test) return next.handle(req);

        return next.handle(req).pipe(
            tap(res => {
                if (res instanceof HttpResponse) {
                    this.versionService.handleVersionCheck(
                        res.clone()
                    );
                }
            }, (errorRes: HttpErrorResponse) => {
                this.versionService.handleVersionCheck(errorRes);
            }),
        );
    }
}
