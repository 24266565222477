import {Component, OnInit} from '@angular/core';
import {
    APIService,
    AuthorizationGetResponseSerializer, EncounterContextResponseSerializer,
    OAuthRedirectURISerializer,
    PatientListSerializer,
} from '../../../@core/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RequestHandler} from '../../../@core/utils/request-handler';
import {UnsubscribeComponent} from '../../../@core/fc-component';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {EMPTY, iif} from 'rxjs';
import {UserAuthService} from '../../../@core/user-auth.service';

interface ExtendedAuthorizationGetResponseSerializer extends AuthorizationGetResponseSerializer {
    first_name: string;
    needsEncounter: boolean;
    needsPatient: boolean;
}
@Component({
    selector: 'app-application-permission',
    templateUrl: './application-permission.component.html',
    styleUrls: ['./application-permission.component.scss'],
    host: {
        class: 'w-100',
    },
})
export class ApplicationPermissionComponent extends UnsubscribeComponent implements OnInit {
    scopes: {key: string; text: string; value: boolean}[] = [];
    patients = [];
    selectedPatient: PatientListSerializer;
    application: ExtendedAuthorizationGetResponseSerializer;
    patientControl = new FormControl<PatientListSerializer>(null);
    encounters: EncounterContextResponseSerializer[];
    selectedEncounter: number;
    isPractitioner = false;

    private _queryParams = this.route.snapshot.queryParams;
    authorizationRequest = new RequestHandler(queryParams => this.api.SMARTAuthorizationView.get(queryParams), {destroy$: this.destroy$});
    submitRequest = new RequestHandler(params => this.api.SMARTAuthorizationView.post(params), {destroy$: this.destroy$});

    constructor(private api: APIService,
                private route: ActivatedRoute,
                private router: Router,
                private userAuthService: UserAuthService) {
        super();
    }

    ngOnInit(): void {
        this.patientControl.valueChanges.pipe(
            tap((patient: PatientListSerializer) => {
                this.selectedPatient = patient;
                this.selectedEncounter = null;
            }),
            switchMap(() =>
                iif(() => this.selectedPatient && this.application?.needsEncounter,
                    this.api.EncounterView.get({patient: this.selectedPatient?.id}),
                    EMPTY
                )
            )
        ).subscribe(encounters => {
            this.encounters = encounters;
        });

        this.userAuthService.user.pipe(takeUntil(this.destroy$)).pipe(
            tap(user => {
                if (user.smart_user_info?.as_practitioner) {
                    this.isPractitioner = true;
                }
                if (user.smart_user_info?.as_patient) {
                    this.patientControl.setValue({id: user.smart_user_info.as_patient});
                }
            }),
            switchMap(() => this.authorizationRequest.call(this._queryParams)
                .pipe(
                    tap(application => this.application =
                        {
                            ...application,
                            first_name: application.application,
                            needsPatient: Object.keys(application.scopes).includes('launch/patient'),
                            needsEncounter: Object.keys(application.scopes).includes('launch/encounter'),
                        }
                    )
                ))
        ).subscribe(resp => {
            Object.keys(resp.scopes).forEach(key => {
                this.scopes.push({key, text: resp.scopes[key], value: true});
            });
        });
    }

    onSubmit() {
        this.submitRequest.call({
            ...this._queryParams,
            patient: this.selectedPatient.id,
            scope: this.scopes.filter(scope => scope.value).map(scope => scope.key).join(' '),
            encounter: this.selectedEncounter,
        }).subscribe((resp: OAuthRedirectURISerializer) => {
            window.location.href = resp.redirect_uri;
        });
    }

    navigateBack() {
        this.userAuthService.logout();
        this.router.navigate(['login'], {queryParams: this._queryParams});
    }
}
