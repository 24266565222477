import {NgModule} from '@angular/core';
import {RouterModule, Routes, ExtraOptions} from '@angular/router';
import {NotFoundComponent} from './@theme/components/not-found/not-found.component';
import {AyvaAuthorizationSuccessComponent} from './user/ayva-authorization-success/ayva-authorization-success.component';
import {PortalGuard} from './user/portal-guard';
import {AuthorizationContainerComponent} from './user/authorization/authorization-container.component';
import {SetPasswordComponent} from './user/authorization/set-password/set-password.component';
import {LoginComponent} from './user/authorization/login/login.component';
import {CantLoginComponent} from './user/authorization/cant-login/cant-login.component';
import {LandingPageComponent} from './user/landing-page/landing-page.component';
import {ApplicationPermissionComponent} from './user/authorization/application-permission/application-permission.component';

const routes: Routes = [
    {
        path: 'ayva-authorization-success',
        component: AyvaAuthorizationSuccessComponent,
    },
    {
        path: '',
        pathMatch: 'full',
        component: LandingPageComponent,
    },
    {
        path: '',
        component: AuthorizationContainerComponent,
        children: [
            {
                path: 'oauth',
                component: LoginComponent,
                data: {
                    title: 'Sign in',
                },
            },
            {
                path: 'login',
                component: LoginComponent,
                data: {
                    title: 'Sign in',
                },
            },
            {
                path: 'set-password',
                component: SetPasswordComponent,
                data: {
                    title: 'Set Password',
                },
            },
            {
                path: 'request-password',
                component: CantLoginComponent,
                data: {
                    title: 'Request Password',
                },
            },
            {
                path: 'application-permission',
                component: ApplicationPermissionComponent,
                data: {
                    title: 'Application Permission Config',
                },
            },
            {
                path: '',
                component: CantLoginComponent,
            },
        ],
    },
    {
        path: '',
        canLoad: [PortalGuard],
        loadChildren: () => import('./portal/portal.module').then(m => m.PortalModule),
    },
    {
        path: '**',
        component: NotFoundComponent,
        data: {
            title: '404',
        },
    },
];

const config: ExtraOptions = {
    useHash: false,
};

@NgModule({
    imports: [
        RouterModule.forRoot(routes, config),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
