import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthorizationContainerComponent} from './authorization-container.component';
import {PortalThemeModule} from '../../portal/@portal-theme/portal-theme.module';
import {LoginComponent} from './login/login.component';
import {SetPasswordComponent} from './set-password/set-password.component';
import {CantLoginComponent} from './cant-login/cant-login.component';
import {ApplicationPermissionComponent} from './application-permission/application-permission.component';

@NgModule({
    declarations: [
        AuthorizationContainerComponent,
        LoginComponent,
        SetPasswordComponent,
        CantLoginComponent,
        ApplicationPermissionComponent,
    ],
    imports: [
        CommonModule,
        PortalThemeModule,
    ],
})
export class AuthorizationContainerModule {
}
