<div class="body">
    <div class="wrapper" id="wrapper">
        <app-gradient-bg></app-gradient-bg>
        <div class="brand-cnt">
            <div class="logo">
                <app-logo></app-logo>
            </div>
            <div class="intro">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 600 600" style="enable-background:new 0 0 600 600;" xml:space="preserve">
                <path id="XMLID_5_" d="M400.6,392.9c-38.3,0-76-16-109.5-46.5l-1.3-1.2l-1.2-1.3c-3.8-4.1-7.2-8.1-10.5-12
                c-3.1-3.6-6.1-7-9.1-10.4l-0.1-0.1c-23.4-25.8-47.1-38.8-70.5-38.8c-0.1,0-0.1,0-0.2,0c-30.6,0.1-57.7,23.1-75,42.4
                c-13.9,15.5-37.8,16.8-53.4,2.9C54.4,314,53.1,290.1,67,274.6c39.5-44,84.8-67.4,131.1-67.5c0.1,0,0.3,0,0.4,0
                c44.9,0,88.6,22,126.4,63.6c3.7,4.1,7.2,8.1,10.5,11.9c2.7,3.2,5.3,6.2,7.9,9.1c20.1,17.9,40.3,26.5,60.2,25.6
                c29.8-1.4,56.3-24.1,73.2-42.8c14-15.5,37.9-16.7,53.4-2.7c15.5,14,16.7,37.9,2.7,53.4c-18.4,20.3-38.1,36.4-58.4,47.7
                c-21.8,12.1-44.5,18.8-67.3,19.9C405,392.9,402.8,392.9,400.6,392.9z"/>
            </svg>
            </div>
        </div>
        <div class="form-cnt">
            <router-outlet></router-outlet>
        </div>
    </div>
    <footer class="footer">
        BroadStreet, Version 1
    </footer>
</div>
