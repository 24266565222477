import {Component, OnInit} from '@angular/core';
import {UserAuthService} from './@core/user-auth.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {themeColors} from './@theme/theme-colors';
import {AnalyticsService} from './@core/utils/analytics.service';
import {debounceTime, filter, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';
import {ConfigService} from './@core/config.service';
import {NgbModalConfig, NgbTooltipConfig} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import {APIService} from './@core/api.service';
import {FilterService} from './@core/filter.service';
import {ToastService} from './@core/toast.service';
import {BreadcrumbService} from 'xng-breadcrumb';
import {SentryErrorHandler} from './@core/sentry-error-handler';
import {SlidePanelService} from './portal/slide-panel/slide-panel.service';
import {UserTrackerService} from './@core/user-tracker.service';
import {Observable, of} from 'rxjs';
import {PageDetails} from './portal/@slide-panel-preview/patient-details/patient-details.component';
import * as Sentry from '@sentry/browser';

@Component({
    selector: 'app-root',
    template: `
        <ngx-loading-bar [color]="'brand-3'|color"></ngx-loading-bar>
        <router-outlet></router-outlet>
    `,
})
export class AppComponent implements OnInit {
    themeColors = themeColors;

    constructor(private config: ConfigService,
                private userAuth: UserAuthService,
                private router: Router,
                private route: ActivatedRoute,
                private analytics: AnalyticsService,
                private titleService: Title,
                private tooltipConfig: NgbTooltipConfig,
                private modalConfig: NgbModalConfig,
                //'breadcrumbService' is injected and not used on purpose.
                // It has to be injected, because it's gonna always loaded into the view when the page reloaded or refreshed
                // and fix the disappear breadcrumbs after refresh.
                private breadcrumbsService: BreadcrumbService,
                private toastService: ToastService,
                private slidePanelService: SlidePanelService,
                private userTrackerService: UserTrackerService,
                httpClient: HttpClient) {
        APIService.init(httpClient);
    }

    ngOnInit(): void {
        //this.analytics.trackPageViews();
        this.tooltipConfig.container = 'body';
        this.tooltipConfig.placement = ['top', 'bottom', 'right', 'left'];
        this.modalConfig.centered = true;

        this.router.events.pipe(
            tap(() => SentryErrorHandler.activeFilter = null),
            filter(e => e instanceof NavigationEnd),
            map(() => this.route.snapshot),
            map(route => {
                while (route.firstChild) route = route.firstChild;
                return route;
            }),
            debounceTime(0)
        ).subscribe(route => {
            if (!route.data.slidePanel) {
                this.breadcrumbsService.breadcrumbs$.pipe(take(1)).subscribe(breadcrumb => {
                    const title = route.data.title || breadcrumb[breadcrumb.length - 1]?.label || '';
                    this.titleService.setTitle(this.config.processTitle(title));
                    this.userTrackerService.trackNavigation(this.router.url.split('?')[0], UserAuthService.userId, this.titleService.getTitle());
                });
            }
            this.analytics.trackPageView();
        });

        this.slidePanelService.panelChange.pipe(
            debounceTime(0),
            filter(() => !!this.slidePanelService.activePanel),
            switchMap<null, Observable<string | null>>(() => {
                const panel = this.slidePanelService.activePanel;
                if (!panel) return of(null);
                if (panel.title) return of(panel.title);
                if (!panel.panelComponent) return of(null);
                if (panel.panelComponent.title) return of(panel.panelComponent.title);
                if (!panel.componentRef) return of(null);
                if (panel.componentRef.instance.pageDetails$) {
                    return panel.componentRef.instance.pageDetails$.pipe(
                        switchMap<PageDetails, Observable<string>>(details => (of(details.title))),
                        takeUntil(this.slidePanelService.panelChange)
                    );
                }
                if (panel.componentRef.instance.pageTitle) return of(panel.componentRef.instance.pageTitle);
                return of(null);
            })
        ).subscribe(title => {
            if (!title) return;
            const processedTitle = this.config.processTitle(title);
            this.userTrackerService.trackNavigation(this.router.url.split('?')[0], UserAuthService.userId, processedTitle);
            this.titleService.setTitle(processedTitle);
        });

        SentryErrorHandler.chunkLoadError.subscribe(() => {
            this.toastService.error(
                `
                    <div>
                        Unable to load application module due to a <strong>network error</strong> or a <strong>new version</strong> of the application being available.
                    </div>
                    <div class="d-flex justify-content-center mt-2">
                        <span class="btn btn-sm btn-white">Refresh</span>
                    </div>`,
                'Refresh browser',
                {
                    enableHtml: true,
                    disableTimeOut: true,
                    closeButton: false,
                    messageClass: 'cursor-pointer',
                }
            ).onTap.subscribe(() => {
                window.location.reload();
            });
        });

        this.userAuth.user.subscribe(user => Sentry.setUser({id: user?.id, email: user?.email}));
        FilterService.activeFilter$.subscribe(x => SentryErrorHandler.activeFilter = x);
    }
}
