import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BreadcrumbModule} from 'xng-breadcrumb';
import {JWTInterceptor} from './@core/injectables/jwt-interceptor';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {NgxPermissionsModule} from 'ngx-permissions';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {ToastrModule} from 'ngx-toastr';
import {ThemeModule} from './@theme/theme.module';
import {VersionCheckInterceptor} from './@core/injectables/version-check-interceptor';
import {ConfirmModule} from './portal/@portal-shared/confirm/confirm.module';
import * as Sentry from '@sentry/browser';
import {BlobErrorInterceptor} from './@core/injectables/blob-error-interceptor';
import {environment} from '../environments/environment';
import {GRPCWebModule} from './@core/grpc-web/grpc-web.module';
import {AyvaAuthorizationSuccessComponent} from './user/ayva-authorization-success/ayva-authorization-success.component';
import {SentryErrorHandler} from './@core/sentry-error-handler';
import {ParamConverterInterceptor} from './@core/injectables/param-converter-interceptor';
import {RouteReuseStrategy} from '@angular/router';
import {SlidePanelRouteReuseStrategy} from './portal/slide-panel/slide-panel-route-reuse-strategy';
import {AuthorizationContainerModule} from './user/authorization/authorization-container.module';
import {UserModule} from './user/user.module';
import {LandingPageComponent} from './user/landing-page/landing-page.component';
import {fetchParameters} from './@core/parameters';
import {TwoFactorAuthInterceptor} from './portal/@portal-theme/components/two-factor-auth/two-factor-auth-interceptor';

if (environment.production && !['localhost', '127.0.0.1'].includes(window.location.hostname)) {
    Sentry.init({
        dsn: 'https://e09900e415b3832178459a1583696982@sentry.arsana.io/2',
        integrations(integrations) {
            return [...integrations.filter(i => i.name !== 'BrowserApiErrors'), Sentry.browserTracingIntegration()];
        },
        tracesSampleRate: 1.0,
        normalizeDepth: 10,
        environment: window.location.hostname.includes('test') ? 'test' : (window.location.hostname.includes('stage') ? 'stage' : (window.location.hostname.includes('broadstreet') ? 'broadstreet' : 'production')),
        release: window['siteVersion'],
    });
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        GRPCWebModule.forRoot({}),
        LoadingBarRouterModule,
        LoadingBarHttpClientModule,
        ThemeModule.forRoot(),
        NgxWebstorageModule.forRoot(),
        BreadcrumbModule,
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-right',
            closeButton: true,
        }),
        NgxPermissionsModule.forRoot(),
        ConfirmModule.forRoot(),
        AuthorizationContainerModule,
        UserModule,
    ],
    declarations: [
        AppComponent,
        AyvaAuthorizationSuccessComponent,
        LandingPageComponent,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: () => fetchParameters,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JWTInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TwoFactorAuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ParamConverterInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: VersionCheckInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BlobErrorInterceptor,
            multi: true,
        },
        {provide: RouteReuseStrategy, useClass: SlidePanelRouteReuseStrategy},
        {provide: ErrorHandler, useClass: SentryErrorHandler},
    ],
    bootstrap: [
        AppComponent,
    ],
    exports: [
        NgxPermissionsModule,
    ],
})
export class AppModule {
}
