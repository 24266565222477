import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserAuthService} from '../@core/user-auth.service';
import {NgxPermissionsModule} from 'ngx-permissions';
import {PortalThemeModule} from '../portal/@portal-theme/portal-theme.module';

@NgModule({
    imports: [
        CommonModule,
        PortalThemeModule,
        NgxPermissionsModule.forChild(),
    ],
    providers: [
        UserAuthService,
    ],
})
export class UserModule {
}
