<ng-container *ngIf="!cantLoginStatus">
    <h3 class="heading">Can't login?</h3>
    <div class="form-group">Request password reset link</div>
    <div class="form-group">
        <div class="error-list" *ngIf="messages && messages.email">{{messages.email}}</div>
        <label for="email">E-mail address</label>
        <input autofocus type="text" [(ngModel)]="email" #tempRefU id="email" name="email">
    </div>
    <div class="submit-cnt">
        <button class="secondary-btn" type="button" (click)="navigateBack()">Back</button>
        <button (click)="onSendPwEmail()" [appUnderProcessing]="forgotPwRequest.isPending" type="button">Send</button>
    </div>
</ng-container>

<ng-container *ngIf="cantLoginStatus === 'ERROR'">
    <h3 class="heading" *ngIf="errorMsgTitle">{{errorMsgTitle}}</h3>
    <div class="form-group" [innerHTML]="configService.contactSupportTextHtml"></div>
    <div class="mt-2">
        <button class="secondary-btn" (click)="navigateBack()" type="button">Back</button>
    </div>
</ng-container>

<ng-container *ngIf="cantLoginStatus === 'SENT'">
    <h3>We've sent you an email with further instructions.<br><strong>Please check your email.</strong></h3>
</ng-container>
