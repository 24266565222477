import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserAuthService} from '../../../@core/user-auth.service';
import {UnsubscribeComponent} from '../../../@core/fc-component';
import {RequestHandler} from '../../../@core/utils/request-handler';
import {from} from 'rxjs';
import {ConfigService} from '../../../@core/config.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends UnsubscribeComponent implements OnInit {
    @ViewChild('tempRefPw') templateRefPw: ElementRef;
    @ViewChild('tempRefU') tempRefU: ElementRef;
    _ua: any;

    email: string;
    password: string;
    messages: any;
    loginRequest = new RequestHandler(
        (email, password) => from(this.userAuthService.login(email, password)),
        {
            showErrorToast: false,
            destroy$: this.destroy$,
        }
    );

    private _queryParams = this.activatedRoute.snapshot.queryParams;
    constructor(public configService: ConfigService,
                private userAuthService: UserAuthService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private ref: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        this._ua = navigator.userAgent.toLowerCase();
        if (this._queryParams?.email) {
            this.email = this._queryParams.email;
        }
    }

    onSubmit(): void {
        delete this.messages;
        if (this._ua.includes('crios') && !this.email && !this.password) {
            this.password = this.templateRefPw.nativeElement.value;
            this.email = this.tempRefU.nativeElement.value;
        }
        this.loginRequest.call(this.email, this.password).subscribe(() => {
            if (this._queryParams.client_id) {
                this.router.navigate(['application-permission'], {queryParams: this._queryParams});
            } else {
                const {target, ...queryParams} = this.activatedRoute.snapshot.queryParams;
                this.router.navigate([target || '/'], {replaceUrl: true, queryParams}).then();
            }
        }, messages => {
            this.messages = messages.non_field_errors || messages.email || messages.password ? messages : {non_field_errors: ['An error occurred while trying to log in. Please try logging in later.']};
        });
    }

    requestNewPassword() {
        this.router.navigate(['request-password'], {queryParams: {email: this._queryParams.email || this.email}});
    }
}
