<h3 class="heading">Set Password</h3>
<ng-container *ngIf="isValid">
    <div class="error-list"
         *ngIf="errors?.detail">
        {{errors.detail}}
    </div>
    <form class="form" (submit)="onSubmit()">
        <div class="form-group">
            <label for="password">E-mail address</label>
            <strong>{{email}}</strong>
        </div>
        <div class="form-group">
            <div class="float-right text-right secondary-text">
                <app-toggle [(ngModel)]="showPassword"
                            label="Show"
                            size="sm"
                            [ngModelOptions]="{standalone: true}">
                </app-toggle>
            </div>
            <div class="error-list"
                 *ngIf="errors?.password">
                {{errors.password}}
            </div>
            <label>Password
                <app-hint appearance="light">
                    <p>The password must consist of a minimum of 8 characters and contain characters from three of the following four categories:</p>
                    <ul>
                        <li>Uppercase character (A - Z)</li>
                        <li>Lowercase character (a – z)</li>
                        <li>Numeric character (0 – 9)</li>
                        <li>Special character (e.g., %, -, !, #)</li>
                    </ul>
                </app-hint>
            </label>
            <input autofocus
                   [attr.type]="showPassword ? 'text' : 'password'"
                   [(ngModel)]="password"
                   id="password"
                   name="password"
                   required>
        </div>
        <div class="error-list"
             *ngIf="errors?.terms">
            {{errors.terms}}
        </div>
        <div class="form-group">
            <app-toggle [ngModel]="acceptTerms"
                        (ngModelChange)="onAcceptTermsChange($event)"
                        [ngModelOptions]="{standalone: true}">
                <span class="ml-2">I've read and accept the
                    <a tabindex="-1"
                       (click)="openTerms($event)"
                       class="text-white strong"
                       id="toggleTermsAndConditions">
                        Terms & Conditions
                    </a>
                </span>
            </app-toggle>
        </div>
        <div class="submit-cnt">
            <button type="submit"
                    [disabled]="!password || !acceptTerms"
                    id="saveNewPasswordButton"
                    [appUnderProcessing]="postCodeVerificationRequest.isPending">
                Save Password
            </button>
        </div>
    </form>
</ng-container>
<ng-container *ngIf="isValid === false">
    <p><strong>The password setting link you're using is expired or invalid.</strong></p>
    <p>Make sure you use the latest password setting link you received. Request a new link if you're still
        having trouble.</p>
    <div class="text-right mt-3">
        <button class="align-self-start"
                (click)="requestNewPassword()">
            Request new link
        </button>
    </div>
</ng-container>
<app-spinner *ngIf="!isValid && isValid !== false"></app-spinner>

<ng-template #termsTmp let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-title">Terms & Conditions</h4>
        <button type="button"
                class="close"
                aria-describedby="modal-title"
                (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h2 class="text-center">USER AGREEMENT
            <br>TERMS AND USE</h2>
        <div class="decimal-ol-wrapper">
            <ol>
                <li>By selecting the “I Accept” box, User agrees to the terms and conditions under which {{siteName}}
                    agrees to allow the User to access certain hosted software through the {{siteName}} Portal,
                    including {{domainText}} (the “Portal”).
                    <ol style="list-style-type: decimal;">
                        <li><u>Authorized Users; Authorized Uses</u>. Subject to the terms and conditions
                            hereof, {{siteName}} hereby grants approved personnel authorized by Facility
                            (“<strong>User</strong>”) to access and use the Services. Provided however, Users are
                            prohibited from accessing the Services from any non-secure or non-password protected access
                            point or network such as provided by a public library, restaurant or similar venue that
                            provides access to the Internet via a wireless network or other connection. With respect to
                            all Users, all authorizations shall be issued by Facility administrator. User shall not
                            permit any third party to use, or provide access by unauthorized users to, the Services.
                        </li>
                    </ol>
                </li>
                <li><u>No Warranty</u>. {{siteName}} makes no representation or warranty, express or implied, regarding
                    the Services and {{siteName}} specifically disclaims the implied warranties of merchantability,
                    fitness for a particular purpose, custom and usage or otherwise. The Services are provided “AS IS”
                    with all faults. Users acknowledges that the Services may not be accessible 100% of the time, that
                    the Services may have bugs, defects, will not be error-free and periodic maintenance of the Services
                    by {{siteName}} will be
                    required. {{siteName}} makes no representation or warranty about the accuracy, reliability,
                    completeness, timeliness of any content, software, text, graphics, links or results product as a
                    result of Licensee’s utilization of the Services. The Services provided by {{siteName}} are for
                    informational purposes only, and are not intended to be a substitute for the professional judgment
                    of a physician or other qualified medical expert.
                </li>
                <li><u>Licensee Data</u>.
                    <ol>
                        <li><u>Ownership</u>. Facility’s data (“<strong>Facility Data</strong>”, which shall also be
                            known and treated by {{siteName}} as Confidential Information) shall include: (a) data
                            collected, used, processed, stored, or generated by the Facility, its personnel or
                            affiliated physicians as the result of the use of the Services; and, (b) protected health
                            information (“<strong>PHI</strong>”) as defined in the Business Associate Agreement and
                            incorporated herein by reference, collected, used, processed, stored, or
                            generated as the result of the use of the Services, including, without limitation, any
                            information that identifies an individual, such as an individual’s social security number or
                            other government-issued identification number, date of birth, address, telephone number,
                            biometric data, mother’s maiden name, email address, credit card information, or an
                            individual’s name in combination with any other of the elements listed herein. Facility Data
                            is, and shall at all times remain, the property of the Facility
                            and under no circumstances shall User share any Facility Data with any third party. This
                            Section shall survive the termination of this Agreement.
                        </li>
                        <li><u>Loss of Data</u>. In the event of any act, error or omission, negligence, misconduct, or
                            breach that compromises or is suspected to compromise the security, confidentiality, or
                            integrity of Facility Data or the physical, technical, administrative, or organizational
                            safeguards put in place by {{siteName}} that relate to the protection of the security,
                            confidentiality, or integrity of Facility Data, {{siteName}} shall, as applicable: (a)
                            notify Facility and Licensee as soon as practicable but no later than
                            twenty-four (24) hours of becoming aware of such occurrence; (b) cooperate with Facility and
                            Licensee in investigating the occurrence, including making available all relevant records,
                            logs, files, data reporting, and other materials required to comply with applicable law or
                            as otherwise required by Facility; (c) perform or take any other actions required to comply
                            with applicable law as a result of the occurrence.
                        </li>
                        <li><u>Privacy Statement</u>. The Business Associate Agreement attached and incorporated herein
                            by reference shall govern the use of PHI
                        </li>
                    </ol>
                </li>
                <li><u>Non-Disclosure of Confidential Information</u>. The parties acknowledge that each party may be
                    exposed to or acquire communication or data of the other party that is confidential, privileged
                    communication not intended to be disclosed to third parties. The provisions of this Section shall
                    survive the termination of this Agreement.
                    <ol>
                        <li><u>Meaning of Confidential Information</u>. For the purposes of this Agreement, the term
                            “<strong>Confidential Information</strong>” shall mean all information and documentation of
                            a party that: (a) has been marked “confidential” or with words of similar meaning, at the
                            time of disclosure by such party; (b) if disclosed orally or not marked “confidential” or
                            with words of similar meaning, was subsequently summarized in writing by the disclosing
                            party and marked “confidential” or with words of similar
                            meaning; and, (c) should reasonably be recognized as confidential information of the
                            disclosing party. The term “Confidential Information” does not include any information or
                            documentation that was: (a) already in the possession of the receiving party without an
                            obligation of confidentiality; (b) developed independently by the receiving party, as
                            demonstrated by the receiving party, without violating the disclosing party’s proprietary
                            rights; (c) obtained from a source other than the disclosing party
                            without an obligation of confidentiality; or, (d) publicly available when received, or
                            thereafter became publicly available (other than through any unauthorized disclosure by,
                            though, or on behalf of, the receiving party). For purposes of this Agreement, in all cases
                            and for all matters, Facility Data and {{siteName}} Data shall be deemed to be Confidential
                            Information.
                        </li>
                        <li><u>Obligation of Confidentiality</u>. The parties agree to hold all Confidential Information
                            in strict confidence and not to copy, reproduce, sell, transfer, or otherwise dispose of,
                            give or disclose such Confidential Information to third parties.
                        </li>
                        <li><u>Cooperation to Prevent Disclosure of Confidential Information</u>. Each party shall use
                            reasonable commercial efforts to assist the other party in identifying and preventing any
                            unauthorized use or disclosure of any Confidential Information. Without limiting the
                            foregoing, each party shall advise the other party immediately in the event either party
                            learns or has reason to believe that any person who has had access to Confidential
                            Information has violated or intends to violate the terms of this Agreement
                            and each party will cooperate with the other party in seeking injunctive or other equitable
                            relief against any such person.
                        </li>
                        <li><u>Confidential Information upon Termination</u>. Upon termination of this Agreement, each
                            party shall, within fifteen (15) calendar days from the date of termination, return to the
                            other party any and all Confidential Information received from the other party, or created
                            or received by a party on behalf of the other party, which are in such party’s possession,
                            custody, or control.
                        </li>
                        <li><u>Third-Party Beneficiaries</u>. There are no third party beneficiaries under this
                            Agreement. Sharing of Facility Data with unauthorized users or third parties is strictly
                            prohibited.
                        </li>
                        <li><u>Limitation of Liability</u>. NOTWITHSTANDING ANY OTHER PROVISION SET FORTH HEREIN,
                            NEITHER PARTY SHALL BE LIABLE FOR ANY INDIRECT, SPECIAL, AND / OR CONSEQUENTIAL DAMAGES
                            ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT. THAT THE LIABILITY OF A PARTY, WHETHER
                            BASED ON AN ACTION OR CLAIM IN CONTRACT, EQUITY, NEGLIGENCE, TORT, OR OTHERWISE FOR ALL
                            EVENTS, ACTS, OR OMISSIONS UNDER THIS AGREEMENT SHALL NOT EXCEED THE FEES PAID OR PAYABLE
                            UNDER THIS AGREEMENT DURING THE PRIOR TWELVE (12) MONTH PERIOD. This
                            Section shall survive the termination of this Agreement.
                        </li>
                    </ol>
                </li>
                <li><u>General</u>.
                    <ol>
                        <li><u>Entire Agreement</u>. The User agrees to be bound by the Physician, Physician Group or
                            Facility License Agreement. This Agreement and its exhibits constitute the entire agreement
                            and supersede any and all previous representations, understandings, or agreements between
                            Licensee and {{siteName}} as to the subject matter hereof. This Agreement may only be
                            amended by an instrument in writing signed physically or electronically by the parties or by
                            means of electronic acceptance through the Portal. This
                            Agreement shall be construed without regard to the party that drafted it. Any ambiguity
                            shall not be interpreted against either party and shall, instead, be resolved in accordance
                            with other applicable rules concerning the interpretation of contracts.
                        </li>
                    </ol>
                </li>
            </ol>
        </div>
        <h2 class="text-center">BUSINESS ASSOCIATE AGREEMENT</h2>
        <ol type="A">
            <li>Business Associate is providing services to Covered Entity under an existing written agreement (the
                “Underlying Agreement”), and Covered Entity wishes to disclose certain information to Business Associate
                pursuant to the terms of such Underlying Agreement, some of which may constitute Protected Health
                Information (“PHI”) (defined below).
            </li>
            <li>Covered Entity and Business Associate intend to protect the privacy and provide for the security of PHI
                disclosed to Business Associate pursuant to the Underlying Agreement in compliance with (i) the Health
                Insurance Portability and Accountability Act of 1996 (“HIPAA”); (ii) Subtitle D of the Health
                Information Technology for Economic and Clinical Health Act (the “HITECH Act”), also known as Title XIII
                of Division A and Title IV of Division B of the American Recovery and Reinvestment Act of 2009; and
                (iii) regulations
                promulgated thereunder by the U.S. Department of Health and Human Services, including the HIPAA Omnibus
                Final Rule (the “HIPAA Final Rule”), which amended the Privacy Rule and the Security Rule (as those
                terms are defined below) pursuant to the HITECH Act, extending certain HIPAA obligations to business
                associates and their subcontractors.
            </li>
            <li>The purpose of this BAA is to satisfy certain standards and requirements of HIPAA, the Privacy Rule and
                the Security Rule (as those terms are defined below), and the HIPAA Final Rule, including, but not
                limited to, Title 45, §§ 164.314(a)(2)(i), 164.502(e) and 164.504(e) of the Code of Federal Regulations
                (“C.F.R.”).
            </li>
        </ol>
        <p>In consideration of the mutual promises below and the exchange of information pursuant to this BAA, the
            Parties agree as follows:</p>
        <ol>
            <li><u>Definitions</u>.
                <ol type="a">
                    <li><u>Capitalized Terms</u>. Capitalized terms used in this BAA and not otherwise defined herein
                        shall have the meanings set forth in the Privacy Rule, the Security Rule, and the HIPAA Final
                        Rule, which definitions are incorporated in this BAA by reference.
                    </li>
                    <li><u>“Breach”</u> has the same meaning given to such term in 45 C.F.R. § 164.402.</li>
                    <li><u>“Designated Record Set”</u> has the same meaning given to such term in 45 C.F.R. § 164.501.
                    </li>
                    <li><u>"Electronic Protected Health Information" or "Electronic PHI"</u> shall have the same meaning
                        given to such term under the Privacy Rule and the Security Rule, including, but not limited to,
                        45 C.F.R. § 160.103, as applied to the information that Business Associate creates, receives,
                        maintains or transmits from or on behalf of Covered Entity.
                    </li>
                    <li><u>“Individual”</u> has the same meaning given to such term in 45 C.F.R. § 160.103 and shall
                        include a person who qualifies as a personal representative in accordance with 45 C.F.R. §
                        164.502(g).
                    </li>
                    <li><u>“Privacy Rule”</u> means the Standards for Privacy of Individually Identifiable Health
                        Information at 45 C.F.R. Part 160 and Part 164, Subparts A and E.
                    </li>
                    <li><u>“Protected Health Information” or “PHI”</u> has the same meaning given to such term in 45
                        C.F.R. § 160.103, as applied to the information created, received, maintained or transmitted by
                        Business Associate from or on behalf of Covered Entity.
                    </li>
                    <li><u>“Required by Law”</u> has the same meaning given to such term in 45 C.F.R. § 164.103.</li>
                    <li><u>“Secretary”</u> means the Secretary of the Department of Health and Human Services or his or
                        her designee.
                    </li>
                    <li><u>"Security Incident"</u> has the same meaning given to such term in 45 C.F.R. § 164.304.</li>
                    <li><u>"Security Rule"</u> means the Security Standards at 45 C.F.R. Part 160 and Part 164, Subparts
                        A and C.
                    </li>
                    <li><u>“Unsecured PHI”</u> has the same meaning given to such term under 45 C.F.R. § 164.402, and
                        guidance promulgated thereunder.
                    </li>
                </ol>
            </li>
            <li><u>Permitted Uses and Disclosures of PHI</u>.
                <ol type="a">
                    <li><u>Uses and Disclosures of PHI Pursuant to Underlying Agreement</u>. Business Associate shall
                        not use or disclose PHI other than as permitted or required to perform functions, activities or
                        services for, or on behalf of, Covered Entity as specified in the Underlying Agreement or as
                        Required by Law. To the extent Business Associate is carrying out any of Covered Entity’s
                        obligations under the Privacy Rule pursuant to the terms of the Underlying Agreement or this
                        BAA, Business Associate shall comply with the
                        requirements of the Privacy Rule that apply to Covered Entity in the performance of such
                        obligation(s). Business Associate may not use or disclose PHI in a manner that would violate the
                        Privacy Rule if done by Covered Entity, except as set forth in Sections 2(b)-(d).
                    </li>
                    <li><u>Permitted Uses of PHI by Business Associate</u>. Except as otherwise limited in this BAA,
                        Business Associate may use PHI for the proper management and administration of Business
                        Associate or to carry out the legal responsibilities of Business Associate.
                    </li>
                    <li><u>Permitted Disclosures of PHI by Business Associate</u>. Except as otherwise limited in this
                        BAA, Business Associate may disclose PHI for the proper management and administration of
                        Business Associate, provided that the disclosures are Required by Law, or Business Associate
                        obtains reasonable assurances from the person to whom the information is disclosed that it will
                        remain confidential and will be used or further disclosed only as Required by Law or for the
                        purpose for which it was disclosed to the person
                        (which purpose must be consistent with the limitations imposed upon Business Associate pursuant
                        to this BAA), and that the person agrees to notify Business Associate of any instances of which
                        it is aware in which the confidentiality of the information has been breached. Business
                        Associate may use PHI to report violations of law to appropriate federal and state authorities,
                        consistent with 45 C.F.R. § 164.502(j)(1).
                    </li>
                    <li><u>Data Aggregation</u>. Except as otherwise limited in this BAA, Business Associate may use PHI
                        to provide Data Aggregation services as permitted by 45 C.F.R. § 164.504(e)(2)(i)(B).
                    </li>
                    <li><u>De-identified Data</u>. Business Associate may create de-identified PHI in accordance with
                        the standards set forth in 45 C.F.R. § 164.514(b) and may use or disclose such de-identified
                        data for any purpose.
                    </li>
                </ol>
            </li>
            <li><u>Obligations of Business Associate</u>.
                <ol type="a">
                    <li><u>Appropriate Safeguards</u>. Business Associate shall use appropriate safeguards and shall
                        comply with the Security Rule with respect to Electronic PHI, to prevent use or disclosure of
                        such information other than as provided for by the Underlying Agreement and this BAA.
                    </li>
                    <li><u>Reporting of Improper Use or Disclosure, Security Incident or Breach</u>. Business Associate
                        shall report to Covered Entity any use or disclosure of PHI not permitted under this BAA, Breach
                        of Unsecured PHI or Security Incident, without unreasonable delay, and in any event no more than
                        five (5) business days following discovery; provided, however, that the Parties acknowledge and
                        agree that this Section constitutes notice by Business Associate to Covered Entity of the
                        ongoing existence and occurrence of
                        attempted but Unsuccessful Security Incidents (as defined below) for which notice to Covered
                        Entity by Business Associate shall be required only upon request. “Unsuccessful Security
                        Incidents” shall include, but not be limited to, pings and other broadcast attacks on Business
                        Associate’s firewall, port scans, unsuccessful log-on attempts, denials of service and any
                        combination of the above, so long as no such incident results in unauthorized access, use or
                        disclosure of PHI. Business Associate’s notification to
                        Covered Entity of a Breach shall include: (i) the identification of each individual whose
                        Unsecured PHI has been, or is reasonably believed by Business Associate to have been, accessed,
                        acquired or disclosed during the Breach; and (ii) any particulars regarding the Breach that
                        Covered Entity would need to include in its notification, as such particulars are identified in
                        45 C.F.R. § 164.404.
                    </li>
                    <li><u>Business Associate’s Agents</u>. In accordance with 45 C.F.R. § 164.502(e)(1)(ii) and 45
                        C.F.R. § 164.308(b)(2), as applicable, Business Associate shall enter into a written agreement
                        with any agent or subcontractor that creates, receives, maintains or transmits PHI on behalf of
                        Business Associate for services provided to Covered Entity, providing that the agent agrees to
                        restrictions and conditions that are substantially similar to those that apply through this BAA
                        to Business Associate with respect to such
                        PHI.
                    </li>
                    <li><u>Access to PHI</u>. To the extent Business Associate has PHI contained in a Designated Record
                        Set, it agrees to make such information available to Covered Entity pursuant to 45 C.F.R. §
                        164.524 within ten (10) business days of Business Associate’s receipt of a written request from
                        Covered Entity; provided, however, that Business Associate is not required to provide such
                        access where the PHI contained in a Designated Record Set is duplicative of the PHI contained in
                        a Designated Record Set possessed by Covered
                        Entity. If an Individual makes a request for access pursuant to 45 C.F.R. § 164.524 directly to
                        Business Associate, or inquires about his or her right to access, Business Associate shall
                        direct the Individual to Covered Entity.
                    </li>
                    <li><u>Amendment of PHI</u>. To the extent Business Associate has PHI contained in a Designated
                        Record Set, it agrees to make such information available to Covered Entity for amendment
                        pursuant to 45 C.F.R. § 164.526 within twenty (20) business days of Business Associate’s receipt
                        of a written request from Covered Entity. If an Individual submits a written request for
                        amendment pursuant to 45 C.F.R. § 164.526 directly to Business Associate, or inquires about his
                        or her right to amendment, Business Associate shall
                        direct the Individual to Covered Entity.
                    </li>
                    <li><u>Documentation of Disclosures</u>. Business Associate agrees to document such disclosures of
                        PHI and information related to such disclosures as would be required for Covered Entity to
                        respond to a request by an Individual for an accounting of disclosures of PHI in accordance with
                        45 C.F.R. § 164.528. Business Associate shall document, at a minimum, the following information
                        (“Disclosure Information”): (i) the date of the disclosure, (ii) the name and, if known, the
                        address of the recipient of the PHI, (iii) a
                        brief description of the PHI disclosed, (iv) the purpose of the disclosure that includes an
                        explanation of the basis for such disclosure, and (v) any additional information required under
                        the HITECH Act and any implementing regulations.
                    </li>
                    <li><u>Accounting of Disclosures</u>. Business Associate agrees to provide to Covered Entity, within
                        twenty (20) business days of Business Associate’s receipt of a written request from Covered
                        Entity, information collected in accordance with Section 3(f) of this BAA, to permit Covered
                        Entity to respond to a request by an Individual for an accounting of disclosures of PHI in
                        accordance with 45 C.F.R. § 164.528. If an Individual makes a request for an accounting of
                        disclosures of PHI pursuant to 45 C.F.R. § 164.528
                        directly to Business Associate, or inquires about his or her right to an accounting of
                        disclosures of PHI, Business Associate shall direct the Individual to Covered Entity.
                    </li>
                    <li><u>Governmental Access to Records</u>. Business Associate shall make its internal practices,
                        books and records relating to the use and disclosure of PHI received from, or created or
                        received by Business Associate on behalf of, Covered Entity available to the Secretary for
                        purposes of the Secretary determining Covered Entity’s compliance with the Privacy Rule.
                    </li>
                    <li><u>Mitigation</u>. To the extent practicable, Business Associate will reasonably cooperate with
                        Covered Entity’s efforts to mitigate a harmful effect that is known to Business Associate of a
                        use or disclosure of PHI that is not permitted by this BAA.
                    </li>
                    <li><u>Minimum Necessary</u>. Business Associate shall request, use and disclose the minimum amount
                        of PHI necessary to accomplish the purpose of the request, use or disclosure, in accordance with
                        45 C.F.R. § 164.514(d), and any amendments thereto.
                    </li>
                    <li><u>HIPAA Final Rule Applicability</u>. Business Associate acknowledges that enactment of the
                        HITECH Act, as implemented by the HIPAA Final Rule, amended certain provisions of HIPAA in ways
                        that now directly regulate, or will on future dates directly regulate, Business Associate under
                        the Privacy Rule and the Security Rule. Business Associate agrees to comply with applicable
                        requirements imposed under the HIPAA Final Rule.
                    </li>
                </ol>
            </li>
            <li><u>Obligations of Covered Entity</u>.
                <ol type="a">
                    <li><u>Notice of Privacy Practices</u>. Covered Entity shall notify Business Associate of any
                        limitation(s) in its notice of privacy practices in accordance with 45 C.F.R. § 164.520, to the
                        extent that such limitation may affect Business Associate’s use or disclosure of PHI.
                    </li>
                    <li><u>Notification of Changes Regarding Individual Permission</u>. Covered Entity shall notify
                        Business Associate of any changes in, or revocation of, permission by an Individual to use or
                        disclose PHI, to the extent that such changes may affect Business Associate’s use or disclosure
                        of PHI. Covered Entity shall obtain any consent or authorization that may be required by the
                        HIPAA Privacy Rule, or applicable state law, prior to furnishing Business Associate with PHI.
                    </li>
                    <li><u>Notification of Restrictions to the Use or Disclosure of PHI</u>. Covered Entity shall notify
                        Business Associate of any restriction to the use or disclosure of PHI that Covered Entity has
                        agreed to in accordance with 45 C.F.R. § 164.522, to the extent that such restriction may affect
                        Business Associate’s use or disclosure of PHI.
                    </li>
                    <li><u>Permissible Requests by Covered Entity</u>. Covered Entity shall not request Business
                        Associate to use or disclose PHI in any manner that would not be permissible under the Privacy
                        Rule, the Security Rule or the HIPAA Final Rule if done by Covered Entity, except as permitted
                        pursuant to the provisions of Section 2 of this BAA.
                    </li>
                </ol>
            </li>
            <li><u>Term and Termination</u>.
                <ol type="a">
                    <li><u>Term</u>. The term of this BAA shall commence as of the BAA Effective Date, and shall
                        terminate when all of the PHI provided by Covered Entity to Business Associate, or created or
                        received by Business Associate on behalf of Covered Entity, is destroyed or returned to Covered
                        Entity or, if it is infeasible to return or destroy PHI, protections are extended to such
                        information, in accordance with Section 5(c).
                    </li>
                    <li><u>Termination for Cause</u>. Upon either Party’s knowledge of a material breach by the other
                        Party of this BAA, such Party shall provide written notice to the breaching Party stating the
                        nature of the breach and providing an opportunity to cure the breach within thirty (30) business
                        days. Upon the expiration of such 30-day cure period, the non-breaching Party may terminate this
                        BAA and, at its election, the Underlying Agreement, if cure is not possible.
                    </li>
                    <li><u>Effect of Termination</u>.
                        <ol type="i">
                            <li>Except as provided in paragraph (ii) of this Section 5(c), upon termination of the
                                Underlying Agreement or this BAA for any reason, Business Associate shall return or
                                destroy all PHI received from Covered Entity, or created or received by Business
                                Associate on behalf of Covered Entity, and shall retain no copies of the PHI.
                            </li>
                            <li>If it is infeasible for Business Associate to return or destroy the PHI upon termination
                                of the Underlying Agreement or this BAA, Business Associate shall: (i) extend the
                                protections of this BAA to such PHI; and (ii) limit further uses and disclosures of such
                                PHI to those purposes that make the return or destruction infeasible, for so long as
                                Business Associate maintains such PHI.
                            </li>
                        </ol>
                    </li>
                </ol>
            </li>
            <li><u>Cooperation in Investigations</u>. The Parties acknowledge that certain breaches or violations of
                this BAA may result in litigation or investigations pursued by federal or state governmental authorities
                of the United States resulting in civil liability or criminal penalties. Each Party shall cooperate in
                good faith in all respects with the other Party in connection with any request by a federal or state
                governmental authority for additional information and documents or any governmental investigation,
                complaint,
                action or other inquiry.
            </li>
            <li><u>Survival</u>. The respective rights and obligations of Business Associate under Section 5(c) of this
                BAA shall survive the termination of the BAA and the Underlying Agreement.
            </li>
            <li><u>Effect of BAA</u>. In the event of any inconsistency between the provisions of this BAA and the
                Underlying Agreement, the provisions of this BAA shall control. In the event of inconsistency between
                the provisions of this BAA and mandatory provisions of the Privacy Rule, the Security Rule or the HIPAA
                Final Rule, or their interpretation by any court or regulatory agency with authority over Business
                Associate or Covered Entity, such interpretation shall control; provided, however, that if any relevant
                provision of the
                Privacy Rule, the Security Rule or the HIPAA Final Rule is amended in a manner that changes the
                obligations of Business Associate or Covered Entity that are embodied in terms of this BAA, then the
                Parties agree to negotiate in good faith appropriate non-financial terms or amendments to this BAA to
                give effect to such revised obligations. Where provisions of this BAA are different from those mandated
                in the Privacy Rule, the Security Rule, or the HIPAA Final Rule, but are nonetheless permitted by such
                rules as
                interpreted by courts or agencies, the provisions of this BAA shall control.
            </li>
            <li><u>General</u>. This BAA is governed by, and shall be construed in accordance with, the laws of the
                State that govern the Underlying Agreement. Covered Entity shall not assign this BAA without the prior
                written consent of Business Associate, which shall not be unreasonably withheld. If any part of a
                provision of this BAA is found illegal or unenforceable, it shall be enforced to the maximum extent
                permissible, and the legality and enforceability of the remainder of that provision and all other
                provisions of this BAA
                shall not be affected. All notices relating to the Parties’ legal rights and remedies under this BAA
                shall be provided in writing to a Party, shall be sent to its address set forth in the signature block
                below, or to such other address as may be designated by that Party by notice to the sending Party, and
                shall reference this BAA. This BAA may be modified, or any rights under it waived, only by a written
                document executed by the authorized representatives of both Parties. Nothing in this BAA shall confer
                any right,
                remedy or obligation upon anyone other than Covered Entity and Business Associate. This BAA is the
                complete and exclusive agreement between the Parties with respect to the subject matter hereof,
                superseding and replacing all prior agreements, communications and understandings (written and oral)
                regarding its subject matter.
            </li>
            <li><u>Independent Contractor</u>. Business Associate will be considered, for all purposes, an independent
                contractor, and Business Associate will not, directly or indirectly, act as agent, servant or employee
                of Covered Entity or make any commitments or incur any liabilities on behalf of Covered Entity without
                its express written consent. Nothing in this BAA shall be deemed to create an employment,
                principal-agent, or partner relationship between the parties. Business Associate shall retain sole and
                absolute discretion in
                the manner and means of carrying out its activities and responsibilities under this BAA.
            </li>
        </ol>
        <p>By selecting the “I Accept” box, User agrees to be bound by the entire Agreement, its exhibits and the Terms
            and Use.</p>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="btn btn-outline-secondary"
                (click)="modal.dismiss()">
            Cancel
        </button>
        <button type="button"
                id="acceptTermsButton"
                class="btn btn-primary"
                (click)="acceptTerms = true; modal.close()">
            I Accept
        </button>
    </div>
</ng-template>
