import {ActivatedRouteSnapshot, BaseRouteReuseStrategy, DetachedRouteHandle} from '@angular/router';
import {inject} from '@angular/core';
import {SlidePanelStackController} from './slide-panel-stack-controller';

export class SlidePanelRouteReuseStrategy extends BaseRouteReuseStrategy {
    navigatingToSlidePanel: boolean;
    stackController = inject(SlidePanelStackController);

    get backgroundRoute() {
        return this.stackController.backgroundRoute;
    }

    set backgroundRoute(x) {
        this.stackController.backgroundRoute = x;
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return !!this.navigatingToSlidePanel && !route.data.slidePanel;
    }

    store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle) {
        this.stackController.backgroundRoute = {route, detachedTree};
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        // shouldAttach for the future route runs before shouldDetach for the previous route - we're setting this flag for the next shouldDetach call
        this.navigatingToSlidePanel = !!route.data.slidePanel;
        return !this.navigatingToSlidePanel && !!this.backgroundRoute && this.backgroundRoute.route.routeConfig === route.routeConfig;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        const detachedTree = this.backgroundRoute.detachedTree;
        return detachedTree;
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return shouldReuseRoute(super.shouldReuseRoute(future, curr), future, curr);
    }
}

export class ReloadOnParamChangeRouteReuseStrategy extends BaseRouteReuseStrategy {
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return shouldReuseRoute(super.shouldReuseRoute(future, curr), future, curr, false);
    }
}

const shouldReuseRoute = (
    defReuse: boolean,
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot,
    overrideForSlidePanelRoutesOnly = true
): boolean => {
    if (!defReuse || overrideForSlidePanelRoutesOnly && !future.data.slidePanel) return defReuse;

    const futureParamKeys = Object.keys(future.params);
    const currParamKeys = Object.keys(curr.params);

    return futureParamKeys.length === currParamKeys.length && futureParamKeys.every(key => future.params[key] === curr.params[key]);
};
